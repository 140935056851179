<!--  -->
<template>
  <div>
    <div class="point">
      
      <!-- 盘点类型 -->
      <div class="point_top">
        <i class="el-icon-arrow-left" @click="back()"></i>
        <div class="point_kind">{{ pointTitle.inventoryTypeName }}</div>
        <div class="point_point">…</div>
      </div>
      <!-- 搜索框 -->
      <div class="point_search" >
        <input
          type="text"
          class="point_input"
          placeholder="请按照编码/盘点名称查询"
          v-model="keyword"
          style="font-size:4vw"
          
        />
        <i class="el-icon-search" @click="rolading()"></i>
      </div>
      <!-- main -->
      <div
        class="point_main"
        v-for="(item, index) in queryList"
        :key="index"
        style="display: flex; font-size: 3.5vw; color: black;font-weight: bolder;"
      >
        <!-- left -->
        <div
          class="point_main_left"
          style="
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          "
        >
          <div>
            <span style="margin-left: 3vw">盘点编码</span
            ><span style="margin-left:5vw">{{ item.inventoryNo }}</span>
          </div>
          <div style="display:flex;width: 70vw;">
            <span style="margin-left: 3vw;vertical-align: top;">盘点名称</span
            ><span style="margin-left:5vw;display: inline-block;">{{ item.inventoryName }}</span>
          </div>
          <div>
            <span style="margin-left: 3vw">盘点方式</span
            ><span style="margin-left:5vw">{{
              item.inventoryMethodName
            }}</span>
          </div>
          <div>
            <span style="margin-left: 3vw">创建时间</span
            ><span style="margin-left:5vw">{{ item.inventoryDate }}</span>
          </div>
          <div>
            <span style="margin-left: 3vw">门店名称</span
            ><span style="margin-left:5vw">{{ item.shop }}</span>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-left: 3.2vw;
            "
          >
            <div>
              <el-button
                type="danger"
                disabled
                style="
                  width: 20.5vw;
                  height: 6vw;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: -3vw;
                  font-size:3.5vw;
                  /* transform: scale(0.8); */
                "
                >未盘 ({{ item.unCounted }})</el-button
              >
            </div>
            <div>
              <el-button
                type="primary"
                disabled
                style="
                  width: 20.5vw;
                  height: 6vw;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size:3.5vw;
                  /* transform: scale(0.8); */
                "
                >已盘 ({{ item.diff }})</el-button
              >
            </div>
            <div>
              <el-button
                type="primary"
                disabled
                style="
                  width: 20.5vw;
                  height: 6vw;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 3.5vw;
                  /* transform: scale(0.8); */
                "
                >未试算 ({{ item.ucal }})</el-button
              >
            </div>
          </div>
        </div>
        <!-- right -->
        <div
          class="point_main_right"
          style="
            width: 30%;
            display: flex;
            height: 43.4vw;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 4.8vw;
          "
        >
          <el-button
            type="danger"
            plain
            v-if="item.auditStatus == 0 || item.auditStatus == 1"
            style="
              width: 22vw;
              height: 6vw;
              border-radius: 1vw;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 3vw;
              font-size: 3.5vw;
              /* transform: scale(0.8); */
            "
            >待审核</el-button
          >
          <el-button
            type="success"
            plain
            v-if="item.auditStatus == 2"
            style="
              width: 22vw;
              height: 6vw;
              border-radius: 1vw;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 3vw;
              font-size: 3.5vw;
              /* transform: scale(0.8); */
            "
            >已审核</el-button
          >
          <!-- <el-button type="primary" plain  style="width:14vw;height:6vw;display: flex;align-items:center;justify-content: center;margin-left: 3vw;" >{{(item.auditStatus==0||item.auditStatus==1)?'待审核':'已审核'}}</el-button> -->
          <el-button
            type="danger"
            style="
              width: 22vw;
              height: 6vw;
              border-radius: 1vw;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 3vw;
              font-size: 3.5vw;
              /* transform: scale(0.8); */
            "
            @click="diff(item.id)"
            >差异查看 ({{ item.counted }})</el-button
          >
          <div>
            <el-button
              type="primary"
              @click="detailTurn(item.id)"
              style="
                width: 22vw;
                height: 6.7vw;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-left: 3.5vw;
                border: 0;
                font-size:4vw;
              "
              v-if="
                item.auditStatus == 0 || item.auditStatus == 1
                  ? '待审核'
                  : '已审核' === '待审核'
              "
              >盘点 &#12288;<img
                src="../assets/icon/sousuo.png"
                alt=""
                style="width: 3.5vw; height: 3.5vw;"
            /></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      keyword: "",
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      pointList: [], //盘点list
      pointTitle: [], //盘点标题
      // pointListA:[],//盘点listA
    };
  },
  mounted() {
    let index = this.$route.params.index;
    console.log(index, "lapk");
    this.inventoryList(index);
  },
  computed: {
    //tableData为数组[]，且为表v-model绑定的参数名
    queryList() {
      var input = this.keyword; //username为input中的v-model参数
      var arr = this.pointList;
      if (input) {
        return arr.filter((data) => {
          return Object.keys(data).some((key) => {
            return String(data[key]).toLowerCase().indexOf(input) > -1;
          });
        });
      }
      return arr;
    },
  },
  components: {},

  // watch:{
  //   keyword(keyword) {
  // 			if (this.keyword === ' ') {
  // 				this.inventoryList()

  // 			};

  // 		},

  // },
  methods: {
    rolading(){
      location.reload();
    },
    // 差异查看
    diff(id) {
      //let id=localStorage.getItem("iid")
      console.log(id, "kdljfl");
      this.$axios
        .get(this.$api.differentList, {
          params: {
            iid: id,
          },
        })
        .then((res) => {
          console.log(res, "differentList");
          if (res.data.code === 0 || res.data.msg === "success") {
            this.$router.push(`./diff/${id}`);
          }
        });
    },
    // 返回上一级
    back() {
      //this.$router.go(-1);
      this.$router.push('/point');
    },
    //跳转到详情
    detailTurn(id) {
      let barCode;
      console.log(id, "aaaaa");
      this.detailList(id);
      this.$router.push(`./details/${id}/${barCode}`);
    },
    // 盘点list
    inventoryList(ind) {
      this.$axios
        .get(this.$api.inventoryList, {
          params: {
            type: ind,
            limit: this.page.pageSize,
            page: this.page.currentPage,
          },
        })
        .then((res) => {
          if(res.data.page.list.length>0){
            this.pointList = res.data.page.list;
            this.pointTitle = res.data.page.list[0];
          console.log(this.pointList, "qqqq");
          }
          else{
            this.$toast({ message: "暂无数据", duration:1000 });
          }
         
          
          
          // this.pointListA=this.pointList
          console.log(this.pointTitle, "this.pointTitle");
        });
    },
    // 详情list
    detailList(detaIlid) {
      this.$axios
        .get(
          "https://babb.boaiyiyao.com/api" +
            `/renren-fast/app/v1/inventory/inventoryInfo/${detaIlid}`,
          {}
        )
        .then((res) => {
          // console.log(res,"id")
        });
    },
    // // 模糊搜索
    // search(){
    //   this.pointList = this.pointList;
    //   //获取到查询的值，并使用toLowerCase():把字符串转换成小写，让模糊查询更加清晰
    //   let _search = this.keyword.toLowerCase();
    //   let newListData = []; // 用于存放搜索出来数据的新数组
    //   if (_search) {
    //     //filter 过滤数组
    //     this.pointList.filter((item) => {
    //       // newListData中 没有查询的内容，就添加到newListData中
    //       if (
    //         item.inventoryNo.toLowerCase().indexOf(_search) !== -1 ||
    //         item.inventoryName.toLowerCase().indexOf(_search) !== -1
    //       ) {
    //         newListData.push(item);
    //       }

    //     });
    //   }
    //   //查询后的表格 赋值过滤后的数据
    //   this.pointList = newListData;
    // },
  },
};
</script>
<style lang='less' scoped>
</style>
<style>
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#dfe3e5, #eef5f8);
  color: #443d3d;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_kind {
  font-weight: 700;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight: bolder;
}
.point_search {
  width: 94%;
  height: 9vw;
  /* border: 1px solid red; */
  border-radius: 1vw;
  margin-left: 3%;
  margin-top: 3vw;
  background-image: linear-gradient(#dfe3e5, #eef5f8);
  display: flex;
  align-items: center;
}
.point_input {
  width: 80%;
  border: 0;
  height: 8vw;
  border-radius: 1vw;
  background-image: linear-gradient(#dfe3e5, #eef5f8);
  font-size: 5px;
  margin-left: 2vw;
}
.el-icon-search {
  font-size: 5vw;
  margin-left: 8vw;
}
.point_main {
  width: 94%;
  height: 50vw;
  border-radius: 1vw;
  margin-left: 3%;
  margin-top: 3vw;
  background-image: linear-gradient(#dfe3e5, #eef5f8);
}
</style>